@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;500;900&display=swap');

* {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: rgb(247, 247, 247);
    margin-bottom: 6rem;
}

p {
    font-size: 1.3rem;
    line-height: 140%;
}
p>a {
    border-bottom: 1px solid;
}
p>a:hover {
    opacity: 0.8;
}

a {
    color: black;
    text-decoration: none;
}
a:hover {
    cursor: pointer;
}

ul {
    list-style: none;
}

img {
    max-width: 100%;
}

.desktopOnly {
    display: block;
}
.mobileOnly {
    display: none;
}

.invisible {
    display: none;
}
.visible {
    display: block;
}





/* ---------------------- */
/* ---------------------- */
/* ---- MOBILE ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    .mobileOnly {
        display: block;
    }
    .desktopOnly {
        display: none;
    }

}