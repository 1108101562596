#services {
    width: 80%;
    margin: 0 auto 6rem auto;
}
#services>h1 {
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    margin: 4rem auto;
    letter-spacing: 1px;
}

#services-services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.services-service-item {
    width: 27%;
    margin: 0 3% auto 3%;
}
.services-service-item:first-of-type {
    width: 85%;
}
.services-service-item>h1 {
    font-size: 1.7rem;
    font-weight: 500;
    text-align: center;
    margin: 0 0 0.6rem 0;
}
.services-service-item>p {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0.7rem;
}
#services-services>img {
    width: 45%;
    margin: 3rem auto;
    border-radius: 25px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
}



/* ---------------------- */
/* ---------------------- */
/* ---- MOBILE ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #services {
        width: 100%;
        margin: 0 auto 4rem auto;
    }
    #services>h1 {
        margin: 4rem auto 2.5rem auto;
    }

    #services-services {
        display: flex;
        flex-direction: column;
    }
    .services-service-item {
        width: 95%;
        margin: 0 auto 3rem auto;
    }
    .services-service-item:first-of-type {
        width: 95%;
    }
    #services-services>img {
        width: 95%;
        margin: 0 auto 3rem;
    }

}