#hero-image>div {
    position: relative;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* #hero-image>div>h1 {
    color: white;
    font-size: 3.5rem;
    font-weight: 900;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 1rem;
} */
#hero-image>div>#hero-logo {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 3rem 1rem;
    max-width: 35%;
}
#hero-image>div>p {
    color: white;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 2px;
    width: 40%;
    text-align: center;
    margin: 0 auto 1.5rem auto;
}

.div-split-view {
    display: flex;
    flex-direction: row;
    margin-top: 6rem;
}
.div-split-view>* {
    width: 48%;
    margin: 0 auto;
}



/* ---------------------- */
/* ---------------------- */
/* ---- DESKTOP ONLY ---- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (min-width: 769px) {

    #hero-image {
        background-image: linear-gradient(rgba(0, 0, 0, 0.219),rgba(0, 0, 0, 0.3)), url('https://renovare-services-com.s3-us-east-2.amazonaws.com/downscaled/F1525F2A-B86F-4C70-AB9B-6B785EBD7597-blur15px.png');
        height: 50rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

}



/* ---------------------- */
/* ---------------------- */
/* ---- MOBILE ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #hero-image-container {
        height: 45rem;
    }

    #hero-image {
        background-image: linear-gradient(rgba(0, 0, 0, 0.219),rgba(0, 0, 0, 0.3)), url('https://renovare-services-com.s3-us-east-2.amazonaws.com/downscaled/F1525F2A-B86F-4C70-AB9B-6B785EBD7597-blur15px.png');
        position: absolute;
        top: 0;
        height: 50rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        z-index: -1;
    }

    #hero-image>div>* {
        top: 32.5%;
        left: 50%;
    }
    /* #hero-image>div>h1 {
        margin-bottom: 3rem;
    } */
    #hero-image>div>#hero-logo {
        padding: 3rem 1rem;
        max-width: 90%;
    }
    #hero-image>div>p {
        letter-spacing: 2px;
        width: 95%;
    }

    .div-split-view {
        display: block;
    }
    .div-split-view>* {
        width: 95%;
    }
    .div-split-view>*:last-child {
        margin-top: 6rem;
    }

}