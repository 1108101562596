#contact-form {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 90%;
    margin: 0 auto;
}
#contact-form>h1 {
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    margin: 0 auto 1.5rem auto;
    letter-spacing: 1px;
}
#contact-form label {
    font-size: 0.925rem;
    font-weight: 500;
    text-align: left;
    width: 100%;
    margin: 0.5rem auto 0 auto;
}
#contact-form input {
    width: 100%;
    height: 2.5rem;
    margin: 0.5rem auto;
    border-radius: 4px;

    font-size: 1.1rem;
    font-weight: 500;
    padding: 0 0.5rem;
    background-color: rgb(235, 235, 235);
}
#contact-form input[type=text] {
    border: none;
}
#contact-form input[type=text]:hover {
    background-color: rgba(235, 235, 235, 0.9);
}
#contact-form button {
    background-color: rgb(127, 113, 133);
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;

    display: flex;
    width: fit-content;
    margin: 1rem auto;
    padding: 0.75rem 1rem;
    
    border: none;
    border-radius: 50px;
    transition: 300ms ease-in-out;
}
#contact-form button:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 300ms ease-in-out;
}

#contact-form-message svg {
    display: block;
    margin: 0 auto;
    width: 2.5rem;
    max-height: 2.5rem;
}
#contact-form-message #form-sending svg {
    fill: black;
    color: black;
}
#contact-form-message #form-done svg {
    color: rgb(127, 113, 133);
}
#contact-form-message p {
    font-size: 1.1rem;
    font-weight: 500;
}



/* ---------------------- */
/* ---------------------- */
/* ---- MOBILE ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #contact-form {
        width: 95%;
        margin: 0 auto;
    }

}