#desktop-nav {
    position: fixed;
    top: 4rem;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: row;

    background-color: rgb(255, 255, 255);
    border-radius: 25px;
    height: fit-content;
    width: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    transition: 300ms ease;

    z-index: 1;
}
#desktop-nav:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.15);
    transition: 300ms ease;
}

#desktop-nav a {
    display: flex;
    color: rgb(25, 25, 25);
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 1px;

    margin: auto 1rem;
    padding: 0.75rem 2.25rem;
    border-radius: 50px;
    transition: 300ms ease;
}
#desktop-nav a:hover {
    cursor: pointer;
    background-color: rgba(203, 188, 209, 0.7);
    transition: 300ms ease;
}

#desktop-nav>img {
    height: 5rem;
    max-width: 10rem;
    padding: 0 2rem;
}

#mobile-nav {
    margin: 0.25rem auto;
    padding: 1rem;

    display: flex;
    flex-direction: column;

    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 25px;
    height: fit-content;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    transition: 300ms ease;

    z-index: 1;
}
#hamburger-menu-icon-region {
    width: fit-content;
    padding: 0.2rem 0.3rem 0 0.3rem;
}
#hamburger-menu-icon-region:hover {
    cursor: pointer;
}
#hamburger-menu-icon-region * {
    stroke: black;
}
#mobile-nav a {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0.4rem auto;
    user-select: none;
}





/* ---------------------- */
/* ---------------------- */
/* ---- DESKTOP ONLY ---- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (min-width: 769px) {

    #desktop-nav>* {
        white-space: nowrap;
        margin: 0 auto;
    }

    #mobile-nav {
        display: none;
    }

}





/* ---------------------- */
/* ---------------------- */
/* ---- MOBILE ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #desktop-nav {
        display: none;
    }

}