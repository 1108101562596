#copyright {
    width: 50%;
    margin: 6rem auto 0 auto;
    text-align: center;
}
#copyright p {
    font-size: 0.7rem;
    margin-bottom: 0.6rem;
}




/* ---------------------- */
/* ---------------------- */
/* ---- MOBILE ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #copyright {
        width: 95%;
    }

}