#pricing-disclosure {
    width: 95%;
    margin: 6rem auto 0 auto;
    text-align: center;
}
#pricing-disclosure p {
    font-size: 0.7rem;
    margin-bottom: 0.6rem;
}




/* ---------------------- */
/* ---------------------- */
/* ---- MOBILE ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #pricing-disclosure {
        width: 95%;
    }

}