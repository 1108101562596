#about {
    display: flex;
    flex-direction: column;
    margin: 0 5% 3rem 5%;
}

#about h1 {
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    margin: 0 auto 1.5rem auto;
    letter-spacing: 1px;
}

#about>img {
    width: 80%;
    margin: 0 auto 1.5rem auto;
    border-radius: 25px;
}

#about p {
    font-size: 1.1rem;
    line-height: 150%;
    margin: 0 auto 1rem auto;
}

#about-socials {
    display: flex;
    justify-content: space-around;
}
#about-socials>a {
    background-color: #6A856A;
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    
    margin: 0 0.25rem;
    padding: 0.75rem 1.25rem;
    border-radius: 50px;
    opacity: 1;
    transition: 300ms ease-in-out;
}
#about-socials>a:hover {
    opacity: 0.8;
    transition: 300ms ease-in-out;
}



/* ---------------------- */
/* ---------------------- */
/* ---- MOBILE ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #about {
        margin: 0 auto 3rem auto;
    }

    #about>img {
        width: 100%;
    }

}