#why-choose {
    width: 80%;
    margin: 0 auto 4rem auto;
}
#why-choose>h1 {
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    margin: 4rem auto;
    letter-spacing: 1px;
}
#why-choose>p {
    font-size: 1.35rem;
    margin: 0 0 0.6rem 0;
}
#why-choose>ul {
    margin-top: 1rem;
}
#why-choose>ul>li:before {
    content: '✓';
    margin-right: 0.5rem;
}
#why-choose>ul>li {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 150%;
    margin: 0 0 0.7rem 3rem;
}



/* ---------------------- */
/* ---------------------- */
/* ---- MOBILE ONLY ----- */
/* ---------------------- */
/* ---------------------- */

@media only screen and (max-width: 768px) {

    #why-choose {
        width: 95%;
        margin: 0 auto;
    }

    #why-choose>ul>li {
        margin: 0 0 0.7rem 0.25rem;
    }

}